<template lang="pug">
    div(class="d-flex flex-column main-container p-3" v-if="!loading")
        consentModal(:consent="consent" :existingRecord='existingRecord')
        b-modal#modalGeneral(centered, title="Edit Profile" hide-footer)
                    b-form-group(label="Name")
                        b-form-input(disabled v-model="caregiverName" type="text").mb-2
                    b-form-group(label="Email")
                        b-form-input(v-model="caregiverEmail" type="text").mb-2
                        p.text-danger(v-if='caregiverEmail && !emailRegexCheck') Please enter valid email address
                    div(class="d-flex justify-content-center").mt-4
                        b-button(:disabled="!emailChanged && !emailRegexCheck" class="px-5 shadow" variant="success" class="mt-3" size="md" @click="updateInfo")
                            | Update
        b-list-group(v-for="(settings,index) in settingsList")
            b-list-group-item.d-flex.align-items-center(@click='triggerModal(settings.type)')
                b-icon(:icon="settings.icon").mr-3
                | {{ settings.name}}
                span.ml-auto.text-muted() >     
        div(class="footer mt-auto" )
            p Version 1.4.2 @ 2024 
</template>
    
    <script>
    
    /* eslint-disable */
    import axios from 'axios';
    import { mapState } from "vuex";
    import consentModal from '../components/setting/consentPopUp.vue'
    export default {
        
        components: {
            consentModal,
        },
        data() {
            return {
                consent: [],
                existingRecord: '',
                loading: false,
                caregiverName: '',
                caregiverEmail: '',
                settingsList : [
                    { name: 'General Info', icon: 'info', type: 'modalGeneral'},
                    { name: 'Media and Publicity', icon: 'shield-lock', type: 'modalConsent'}
                ],
            };
        },
        async mounted(){
            this.caregiverName = this.$root.currentUser[0].crb5c_name ? this.$root.currentUser[0].crb5c_name : '';
            this.caregiverEmail = this.$root.currentUser[0].crb5c_email ? this.$root.currentUser[0].crb5c_email : '';
            const {data} = await axios.get(`/api/records/${this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid}`)
            // console.log('data',data)
            if (data[0] && data[0].crb5c_fowconsentrecordid) {
                this.existingRecord = data[0].crb5c_fowconsentrecordid;
            }
            this.checkConsent(data[0]);
        },
        methods: {
            checkConsent(val){
                if (val.crb5c_consent1 == 1) {
                    this.consent.push('consent-1')
                }
                if (val.crb5c_consent2 == 1) {
                    this.consent.push('consent-2')
                }
            },
            async updateInfo(){
                const payload = {
                    // name : this.caregiverName,
                    email: this.caregiverEmail,
                    caregiver: this.$root.currentUser[0].crb5c_fow_caregiverid,
                }
                const response = await axios.patch(`/api/records`, payload);
                if(response){
                    this.$bvToast.toast('Info Updated', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    });
                    this.$bvModal.hide('modalGeneral');
                }
            },
            triggerModal(modal){
                this.$bvModal.show(modal);
            },   
        },
        watch: {
            
        },
        computed: {
            ...mapState({
            caregiver: state => state.caregiver,
        }),
        emailChanged(){
            const exisitingEmail = this.$root.currentUser[0].crb5c_email || '';
            return this.caregiverEmail !== exisitingEmail && this.emailRegexCheck;
        },
        emailRegexCheck(){
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return this.caregiverEmail && emailPattern.test(this.caregiverEmail);
        }
        }
    
    };
    </script>
    
    <style lang="scss" scoped>
    .main-container {
        height: 90vh;
        width: 100%;
        background-color: #E9EFEC;
        overflow: auto;
        
    }

    .footer {
        margin-top: auto;
        // background-color: #f8f9fa;
        padding: 10px;
        text-align: center;
        border-top: 1px solid #ddd;
    }

    .footer p {
        margin: 0;
        font-size: 14px;
        color: #a2a6a9;
    }
    </style>
    