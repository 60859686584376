<template lang="pug">
div(class="w-100 d-flex flex-column p-2" style="height:90vh;overflow-y: auto; gap: 1rem;")
    div(v-if="!loading && !error" )
        pinned-announcement(:pinned="pinned" :showModal.sync="showModal"  @update:selectedAnnouncement="showModalAnnouncement")
                        
        div(class="d-flex flex-column w-100 h-fit  mt-4 px-2" style="overflow-y: auto; gap: 0.5rem;")
            div(class="d-flex w-100 justify-content-between align-items-center")
                h6(class="text-nowrap m-0 title-name") Announcements 
                b-form-input(type="month" v-model="selectedMonth" class=" form-input-month" @change="monthsSelected")
            div(v-if="!reloading")
                div(v-for="announcement in announcements" :key="announcement.crb5c_fowannouncementid" class="d-flex shadow-md card-announcement flex-column p-3 border-2 my-2" style="background-color: #fff; gap: 1rem;")
                    div(class="d-flex w-100 justify-content-between mt-2")
                        h6 {{announcement.crb5c_title}} 
                            span(v-if=" announcement?.crb5c_expiry && new Date(announcement?.crb5c_expiry) < new Date()" class="badge badge-danger") Expired Pinned
                        p(class="date-announcement") {{new Date(announcement.createdon).toLocaleDateString()}}
                    div(v-html="truncateText(JSON.parse(announcement.crb5c_body), 200)")
                    div(class="d-flex w-100 align-items-center")
                        p(class="view-more m-0 p-0" @click="showModalAnnouncement(announcement)") Read More
                        b-icon(icon="chevron-right")
            div(v-else class="d-flex w-100 mt-5 h-100 justify-content-center align-items-center")
                span(class="spinner-border text-primary")
            div(v-if="announcements.length === 0" class="d-flex w-100 h-100 justify-content-center align-items-center")
                h6(class="mt-5") No Announcements Found

        b-modal(v-model="showModal" title="Announcement" size="is-large" scrollable)
            div(class="d-flex flex-column w-100")
                div(class="d-flex w-100 justify-content-between mt-2")
                    h5 {{selectedAnnouncement?.crb5c_title}}
                    p(class="date-announcement") {{new Date(selectedAnnouncement?.createdon).toLocaleDateString()}}
                div(v-html="selectedAnnouncement?.crb5c_body ? JSON.parse(selectedAnnouncement?.crb5c_body) : ''" class="mt-4")
            template(v-slot:modal-footer)
                b-button(class="button is-success" @click="showModal = false") Close    
    div(v-if="loading" class="d-flex w-100 h-100 flex-column align-items-center")
        b-skeleton(height="30vh" width="100%" class="my-2")
        b-skeleton(height="5vh" width="100%" class="my-2")
        b-skeleton(height="15vh" width="100%" class="my-2")
    div(v-if="error" class="d-flex w-100 h-100 flex-column align-items-center")
        lottie-animation(:animation-data="animationData" :autoPlay="true" :loop="true" :speed="1" ref="anim"  class="w-100")
        p(class="text-danger text-center") Its not you, its us. Something went wrong. Please try again later.
        b-button(@click="loadAnnouncement" variant="primary" class="mt-2") Try Again
                
                
            
</template>


<script>
import PinnedAnnouncement from "@/components/PinnedAnnouncement.vue";
import axios from "axios";
import errorAnimation from "@/assets/errorAnimation.json";
import LottieAnimation from 'lottie-web-vue'
    export default {
        components: {
            PinnedAnnouncement,
            LottieAnimation
        },
        data() {
            return {
                animationData: errorAnimation,
                announcements: [],
                pinned: [],
                showModal: false,
                selectedAnnouncement: {
                    crb5c_title: "",
                    crb5c_body: "",
                    createdon: "",
                },
                loading: false,
                selectedMonth: this.$root.dayjs().format("YYYY-MM"),
                reloading: false,
                error: false,
            };

        },
        async mounted() {
            this.loadAnnouncement();
        },
        methods: {
            async loadAnnouncement(){
                this.loading = true;
                this.error = false;
            try{
            await this.loadCurrentUser();
            if (this.$root.currentUser[0] && this.$root.currentUser[0]?.nextSession){
            const sessionIds = this.$root.currentUser[0]?.nextSession?.map(ses => ses.session?._crb5c_sessionscheduler_value) || [];
            const {data} = await axios.get(`/api/announcements/v2`,{
                params: { groupID: sessionIds }
            });
            let {pinned,general} = data;
            this.announcements = general;
            this.pinned = pinned;
            }
            }catch(err){
                console.log(err);
                this.error = true;               
            }finally{
                this.loading = false;
            }
            },
            async monthsSelected() {
                this.reloading = true;
                try {
                    if (this.$root.currentUser[0] && this.$root.currentUser[0]?.nextSession) {
                        const sessionIds = this.$root.currentUser[0]?.nextSession?.map(ses => ses.session?._crb5c_sessionscheduler_value) || [];
                        const {data} = await axios.get(`/api/announcements/v2`,{
                            params: { groupID: sessionIds }
                        });
                        let { pinned, general } = data;
                        this.announcements = general;
                        this.pinned = pinned;
                    }
                } catch (err) {
                    console.log(err);
                } finally {
                    this.reloading = false;
                }
            },
            async loadCurrentUser() {
            while (!this.$root.currentUser[0]) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        },
            showModalAnnouncement(announcement){
                // console.log(announcement);
                this.selectedAnnouncement = announcement;
                // console.log(this.selectedAnnouncement);
                this.showModal = true;
            },
            truncateText(text, length) {
                if (text.length > length) {
                    return text.substring(0, length) + "...";
                } else {
                    return text;
                }
            },
        },

    }
</script>

<style lang="scss" scoped>
.pinned-button{
    width: 100%;
    height: 10vh;
    background-color: #8967b3;
    outline: none;
    border: none;
    border-radius: 10px;
}
.pinned-active-button{
    border-radius: 10px 10px 0px 0px;
}
@keyframes icon-fade {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}
@keyframes icon-fade-up {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}
.pinned-announcement {
    background-color: #8967b3;
    border-radius: 0px 0px 10px 10px;
    padding: 1rem;
    gap: 1rem;
    animation: icon-fade 0.5s ease-in-out;
    transform-origin: top;
}
.fade-up{
    animation: icon-fade-up 0.5s ease-in-out;
    transform-origin: top;
}
.date-announcement{
    font-size: smaller;
}
.view-more{
    color:#8967b3;
    width: fit-content;
    border-bottom: 1px solid #8967b3;
    font-size: smaller;
}

.bi-chevron-right{
    color: #8967b3;
}
.card-announcement{
    border: 0.5px solid #8967b3;
    border-radius: 10px;
    
    
}
.spinner-border{
    //color #8967b3

    border: 0.15rem dashed #8967b3;
}
.title-name{
    
}
.form-input-month{
    width: 50%;
    height: 5vh;
    border: 1px solid #8967b3;
    border-radius: 10px;
}
</style>