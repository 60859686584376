<template lang="pug">
div
    div(class="d-flex flex-column main-container p-2" v-if="!loading && !error")
        b-tabs.card-pills.card-accent-tabs(class="w-100")
            b-tab(v-for="tab in eventTabs" :key="tab.title" :title="tab.title" :title-link-class="linkClass(tab.title)")
                div(v-for="event in tab.events" :key="event.crb5c_foweventmanagementid" class="mb-2")
                    EventCard(:event="event" @goToDetails="goToDetails")
                div(v-if="!tab?.events?.length")
                    b-card(class="my-2") No events found
    
    
    
    div(v-if="loading" class="d-flex w-100 h-100 flex-column align-items-center p-2")
        div(class="tabs-skeleton w-100 d-flex" style="height: 50px;gap:5px;" )
            b-skeleton(height="5vh" width="30%" class="my-2" v-for="i in 3")
        b-skeleton(height="30vh" width="100%" class="my-2")
        b-skeleton(height="30vh" width="100%" class="my-2")
    div(v-if="error" class="d-flex w-100 h-100 flex-column align-items-center p-2")
        lottie-animation(:animation-data="animationData" :autoPlay="true" :loop="true" :speed="1" ref="anim"  class="w-100")
        p(class="text-danger text-center") Its not you, its us. Something went wrong. Please try again later.
        b-button(@click="loadEvents" variant="primary" class="mt-2") Try Again

</template>

<script>
/* eslint-disable */
import axios from "axios";
import EventCard from "@/components/EventCard.vue";
import errorAnimation from "@/assets/errorAnimation.json";
import LottieAnimation from 'lottie-web-vue'
export default {
    components: {
        EventCard,
        LottieAnimation
    },
    data() {
        return {
            animationData:errorAnimation,
            allEvents: [],
            upcomingEvents: [],
            pastEvents: [],
            loading: false,
            error:true,
            eventTabs: [
                { title: "On Going/Upcoming", events: this.upcomingEvents },
                { title: "Past", events: this.pastEvents },
                { title: "All", events: this.allEvents },
            ]
            
        };
    },
    methods: {
        linkClass(title) {
            return title === "On Going/Upcoming" ? "text-success" : title === "Past" ? "text-danger" : "text-secondary";
        },
        goToDetails(id){
            this.$router.replace(`/event/${id}`);
        },
        async loadCurrentUser() {
            while (!this.$root.currentUser[0]) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        },
        processEvents(eventsData, targetArray) {
        let clientID = this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid;
        let caregiverID = this.$root.currentUser[0].crb5c_fow_caregiverid;

        eventsData.forEach((event) => {
            let jsonData = JSON.parse(event.crb5c_eventjson);

            let submission = this.checkSubmission(jsonData, clientID, caregiverID);
            // console.log(submission);
            let notAttending = this.checkNotAttending(jsonData, clientID, caregiverID);

            targetArray.push({
                ...event,
                jsonData,
                submitted: submission ? true : false,
                notAttending: notAttending ? true : false,
            });
        });
    },

    checkSubmission(jsonData, clientID, caregiverID) {
        if (jsonData?.submissions) {
            return jsonData.submissions.find((submission) => {
                return submission.client === clientID && submission.caregiver === caregiverID;
            });
        }
        return false;
    },

    checkNotAttending(jsonData, clientID, caregiverID) {
        if (jsonData?.notAttending) {
            return jsonData.notAttending.find((notAttending) => {
                return notAttending.client === clientID && notAttending.caregiver === caregiverID;
            });
        }
        return false;
    },

    sortEvents(eventsArray) {
        eventsArray.sort((a, b) => new Date(b.crb5c_date) - new Date(a.crb5c_date));
    },
    async loadEvents() {
            try {
                this.error = false;
                this.loading = true;
                await this.loadCurrentUser();
                // console.log(this.$root.currentUser[0]);
                if (this.$root.currentUser[0] && this.$root.currentUser[0]?.nextSession) {
                    const sessionIds = this.$root.currentUser[0]?.nextSession?.map(ses => ses.session?._crb5c_sessionscheduler_value) || [];
                    console.log('sessionIds',sessionIds)
                    axios
                        .get(`/api/events/v2`, {
                            params: { listID: sessionIds }
                        })
                        .then((response) => {
                            // console.log("triggered", this.loading);
                            // console.log('response',response)
                            if (response.data.all.length === 0) {
                                this.loading = false;
                                return;
                            }

                            this.processEvents(response.data.all, this.allEvents);
                            this.processEvents(response.data.upcomingEvents, this.upcomingEvents);
                            this.processEvents(response.data.pastEvents, this.pastEvents);


                            this.sortEvents(this.allEvents);
                            this.sortEvents(this.upcomingEvents);
                            this.sortEvents(this.pastEvents);

                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error("Error fetching events:", error);
                            this.error = true;
                            this.loading = false;
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            } catch (e) {
                console.log(e);
                this.loading = false;
                this.error = true;
            }
    }
    },
    async mounted() {
        this.loadEvents();
   
},
    watch: {
        upcomingEvents(newVal) {
            this.eventTabs[0].events = newVal;
        },
        pastEvents(newVal) {
            this.eventTabs[1].events = newVal;
        },
        allEvents(newVal) {
            this.eventTabs[2].events = newVal;
        },
    },

};
</script>

<style lang="scss" scoped>
.main-container {
    height: 90vh;
    width: 100%;
    background-color: #E9EFEC;
    overflow: auto;
}
.card-title {
    font-size: 1.5rem;
    margin: 0 !important;
}
.text-purple {
    color: #50276b !important;
}
</style>
