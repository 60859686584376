<template lang="pug">
div(class="custom-success-card mb-2 relative mt-2 d-flex flex-column align-items-center")
    b-icon(icon="check2-circle" class="circle-on-top") 
    div(class="d-flex flex-column align-items-center info w-100")
        h5(class="m-0 font-weight-bolder text-black") Event Submission Successful
        p(class="m-0 font-weight-light mt-1") Your submission has been successfully submitted
    div(class="d-flex flex-row justify-content-between align-items-center summary mt-3")
        div(class="d-flex flex-column align-items-center")
            h6(class="m-0 font-weight-bolder text-black") {{ event.totalAttending }}
            p(class="m-0 font-weight-light mt-1") Attending 
        div(class="d-flex flex-column align-items-center" v-if="event.totalCost !==0")
            h6(class="m-0 font-weight-bolder text-black")
                
                span() $ {{ (event.totalCost + (event?.totalCost * (gst / 100))).toFixed(2) }}
            p(class="m-0 font-weight-light mt-1") Cost
        div(class="d-flex flex-column align-items-center" v-if="event?.multipleDays?.whoGoing.length > 0")
            h6(class="m-0 font-weight-bolder text-black") {{event?.multipleDays?.client?.attendingDays.length}}
            p(class="m-0 font-weight-light mt-1") Days
        div(class="d-flex flex-column align-items-center" v-else)
            h6(class="m-0 font-weight-bolder text-black") Single 
            p(class="m-0 font-weight-light mt-1") Day Event
    button(@click="showSubmissionTrigger" variant="primary" class="button") View Details
        
        
        
    
        
            
        
    
    

</template>

<script>
    export default {
        props:{
            event:{
                type:Object,
                required:true
            },
            gst:{
                type:String,
                required:true
            }
        },
        methods:{
            showSubmissionTrigger(){
                this.$emit('showSubmissionModal');
            }
        }
    }
</script>

<style lang="scss" scoped>
.custom-success-card{
    width: 100%;
    height:fit-content;
    background-color: #96E7BB;
    border-radius: 4px;
    position: relative;
    .circle-on-top{
        width: 50px;
        height: 50px;
        background-color: #fff;
        color: #96E7BB;
        border-radius: 50%;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .info{
        margin-top: 50px;
    }
    .summary{
        width: 90%;
        
        div{
            width: 33.33%;
        }
    }
    .button{
        width: 100%;
        margin-top: 20px;
        background-color: #B0EDCD;
        border: none;
        color: #00000080;
        padding: 10px;

    }
    .button:active{
        background-color: #96E7BB;
        color: #000000;

    }
}

</style>